import { Container } from "../../../components";
import { PatientFormService } from "../../../services";
import { useParams } from "react-router";
import { useRef, useState } from "react";
import CurecallSlider from "./CurecallSlider";
import HandSwitch from "../../../assets/images/svg/HandSwitch.svg";
import {FormQualityOfLifeHorusEnd} from "./QualityOfLifeHorusConfirmEnd";

export const QualityOfLifeHorusSingleQuestion = ({
  formVariable,
  currentStep,
  getNextStep,
  previousStep,
  formVariablesLength,
  locale,
}: {
  formVariable: any;
  currentStep: number;
  formVariablesLength: number;
  getNextStep: (response: any) => void;
  previousStep: () => void;
  locale: string;
}) => {
  const { patientFormId } = useParams();
  const topRef = useRef<HTMLProgressElement>(null);

  const [value, setValue] = useState<string>("50");

    const [displayConfirmationPage, setDisplayConfirmationPage] = useState(false);
  const handleNext = async () => {
      if(!displayConfirmationPage && formVariablesLength ===  (currentStep + 1)) {
        setDisplayConfirmationPage(true);
      }else {
          const body = {
              VariableId: formVariable?.Variable.id,
              patientAnswer: value,
          };
          const { data }: any =
              await PatientFormService.postValueToPatientFormByPatientFormId(
                  patientFormId || "",
                  body
              );
          topRef.current?.scrollIntoView();
          getNextStep(await data);
          setValue("50");
      }
  };

  return (
    <>
      {displayConfirmationPage ? (
        <FormQualityOfLifeHorusEnd
          currentStep={currentStep}
          formVariablesLength={formVariablesLength}
          previousStep={() => {
            setDisplayConfirmationPage(false);
          }}
          handleNext={handleNext}
          topRef={topRef}
        />
      ) : (
        <Container>
          <progress
            ref={topRef}
            max="100"
            value={(currentStep / formVariablesLength) * 100}
          />

          <u className="text-primary" onClick={() => previousStep()}>
            Poprzednia strona
          </u>

          <h1
            dangerouslySetInnerHTML={{
              __html:
                formVariable?.Variable?.intlLabel?.[locale] ||
                formVariable?.Variable.label,
            }}
          />

          <div className=" flex flex-col gap-8 items-center justify-center">
            <CurecallSlider
              id={formVariable?.Variable.id.toString()}
              value={value}
              setValue={setValue}
              caption1={
                formVariable?.Variable?.settings?.questionCaptions?.caption1?.[
                  locale
                ] || ""
              }
              caption2={
                formVariable?.Variable?.settings?.questionCaptions?.caption2?.[
                  locale
                ] || ""
              }
            />
            <img
              src={HandSwitch}
              className="mt-6"
              alt=""
              style={{ width: "10rem" }}
            />
          </div>

          {formVariable.Variable.type === "INTEGER_VALUE_RANGE" && (
            <button
              className="mt-auto"
              onClick={() => {
                handleNext();
              }}
            >
              NASTĘPNE PYTANIE
            </button>
          )}
        </Container>
      )}
    </>
  );
};
